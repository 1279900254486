import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'gatsby';
import Layout from '../../../components/Layout';
import Seo from '../../../components/Seo';
import WistiaPopoverVideo from '../../../components/WistiaPopoverVideo';

import './style.scss';

const Page = () => {
    return (
        <Layout navTheme="light">
            <Seo title="Vendor Onboarding - Content Selection" />
            <div className="content-selection-page">
                <Container className="container-1">
                    <Row className="center">
                        <Col className="centered-text header-text">
                            <h1 className="white-text max-width-900 margin-auto">
                                Picking The Right Kind of Content
                            </h1>
                        </Col>
                    </Row>
                    <Row className="center video-row">
                        <Col className="centered-text max-width-900 video">
                            <WistiaPopoverVideo id={'kb8ta52syt'}>
                                <div className="video-wrapper">
                                    <img
                                        className="play-button"
                                        src={require('../../../images/vendor-onboarding/play-button.svg')}
                                    />
                                    <img
                                        className="thumbnail"
                                        src={require('../../../images/vendor-onboarding/content-selection/thumbnail.png')}
                                        alt="Content selection video thumbnail"
                                    />
                                </div>
                            </WistiaPopoverVideo>
                        </Col>
                    </Row>
                    <img
                        className="white-divider"
                        src={require('../../../svgs/common/white-divider.svg')}
                        alt="white divider"
                    />
                </Container>
                <Container className="container-2">
                    <Row>
                        <Col className="introduction-col">
                            <p>
                                In this guide, we will discuss what the right
                                kind of content you should use with your partner
                                program. We will go through a few considerations
                                and how they can affect the content you select
                                for your program.
                            </p>
                        </Col>
                    </Row>
                    <Row className="funnel-strategy-row">
                        <Col>
                            <h3 className="title">Funnel strategy</h3>
                            <p>
                                We recommend working with an awareness,
                                consideration, and decision content strategy
                                that leads the prospect through a journey
                                covering all three. You can divide these steps
                                evenly across your program’s time frame. For
                                example, a 3 month campaign can focus on month 1
                                being awareness, month 2 being consideration,
                                and month 3 being decision.
                            </p>
                        </Col>
                    </Row>
                    <Row className="awareness-row">
                        <Col className="one-half">
                            <h3 className="title">
                                Awareness, consideration, and decision?
                            </h3>
                            <p className="awareness-section">
                                <strong className="highlight">Awareness</strong>{' '}
                                is about introducing your prospects to the
                                solutions and or services. However, instead of
                                simply offering your solution and its benefits
                                we recommend leveraging education to help
                                prospects have a better understanding of the
                                environment that creates the problem your
                                service is trying to solve and potentially DIY
                                or process solutions to those problems. Your
                                goal should be to provide useful and helpful
                                content that positions your prospect as a
                                subject expert and creates trust.
                            </p>

                            <p className="consideration-section">
                                <strong className="highlight">
                                    Consideration
                                </strong>{' '}
                                is all about providing prospects the information
                                they need to consider your services/solutions.
                                This includes information about how it works,
                                how it compares to other solutions/services and
                                technical details if required. This is great for
                                testimonials and case studies.
                            </p>

                            <p className="decision-section">
                                <strong className="highlight">Decision</strong>{' '}
                                is the stage of the journey the prospect would
                                have been made aware of the solution and has
                                been educated on the solutions details and
                                comparables. The last stage is about making the
                                case to take the final step of buying or taking
                                that meeting. This is a good time to offer
                                specials, free consultations, etc.
                            </p>
                        </Col>
                        <Col className="one-half graph-image-col">
                            <img
                                className="graphs"
                                src={require('../../../images/vendor-onboarding/content-selection/graphs.png')}
                                alt="graph"
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col className="asset-types-column">
                            <h3 className="title">
                                What kind of assets do I need?
                            </h3>
                            <p>We recommend each month includes:</p>

                            <ul>
                                <li>1 landing page</li>
                                <li>2 designed emails</li>
                                <li>
                                    2 plain text emails, we refer to these as
                                    Personal Marketing Emails (PMP’s)
                                </li>
                                <li>
                                    1 content asset (whitepaper, eBook, video
                                    etc.)
                                </li>
                            </ul>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="customization-row">
                            <h3 className="title">Can I customize it?</h3>
                            <p>
                                Yes, while we want to ensure we execute all
                                items on your SOW for the program, there is some
                                flexibility in the campaign structure. For
                                example, you can have each month lead to a
                                webinar making that webinar one of the “content
                                assets” each month. You can also opt to have 2
                                “content assets” each month and have only one
                                landing page we direct to all three months. If
                                you have any questions on how we can customize
                                please ask your account manager and we can help
                                you structure the campaign that fits your
                                approach
                            </p>
                        </Col>
                    </Row>
                    <Row className="target-audience">
                        <Col className="one-half">
                            <img
                                className="magnet-icon"
                                src={require('../../../images/vendor-onboarding/content-selection/magnet-icon.png')}
                                alt="magnet icon"
                            />
                        </Col>
                        <Col className="one-half">
                            <h3 className="title">Target Audience</h3>
                            <p>
                                Lastly, consider the target audience. This has a
                                lot to do with the partner selection process but
                                you will want to match the content you have
                                selected with the partners viable prospect
                                targets. If most or all of your partners are
                                geared to working with 10-25 employee sized
                                companies, we want to make sure the content
                                speaks to those targets. This is also true for
                                specific verticals.
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p>
                                In order to keep this information simple to
                                consume and easy to remember, we have split
                                these onboarding topics into a series. You
                                should expect to receive the next topic soon but
                                if you would like to keep reading please click
                                on any of the following links below.
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="vendor-onboarding-wrapper">
                            <Link
                                to="/vendor-onboarding/expectations"
                                className="vendor-onboarding part"
                            >
                                Part 1: Expectations
                            </Link>
                            <Link
                                to="/vendor-onboarding/selecting-the-right-partner"
                                className="vendor-onboarding part"
                            >
                                Part 3: Proper Partner Selection
                            </Link>
                            <Link
                                to="/vendor-onboarding/ensure-ongoing-success"
                                className="vendor-onboarding part"
                            >
                                Part 4: Ensuring Ongoing Success
                            </Link>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="contact-us-col">
                            <p>
                                Please don’t hesitate to{' '}
                                <a
                                    href="mailto:info@glasshive.com"
                                    className="contact-us"
                                >
                                    contact us
                                </a>{' '}
                                if you have any questions.
                            </p>
                        </Col>
                    </Row>
                </Container>
            </div>
        </Layout>
    );
};

export default Page;
